import React from 'react';
import { Helmet } from 'react-helmet';

import './style.sass';


const Transparencia = () => (
  <main className="Transparencia">
    <Helmet>
      <title>Transparência Salarial | FRIBAL - Especialistas em Carne</title>
    </Helmet>
    <section className="Transparencia__content">
      <h2 className="Transparencia__title">Transparência Salarial</h2>
      <div className="Transparencia__container">
        <section className="Transparencia__intro">
          <p>
            Em atendimento ao disposto na Lei Federal nº 14.611/2023, na Portaria MTE Nº 3.714, bem como nos compromissos da
            FRIBAL para a promoção de práticas de inclusão e diversidade, divulgamos os Relatórios de Transparência Salarial e
            Critérios de Remuneração do Grupo Fribal, conforme a classificação das filiais com 100 colaboradores ou mais.
          </p>
          <p>
            Os relatórios a seguir foram elaborados pelo Ministério do Trabalho e Emprego, do Governo Federal, e levaram em conta
            informações do e-Social do ano base de cada arquivo. O relatório utiliza uma classificação chamada “Grandes Grupos”, que
            reúne diferentes tipos de trabalho (cargos/departamentos).
          </p>
          <p>
            As informações foram divulgadas pelo Ministério do Trabalho e Emprego de acordo com os Grandes Grupos da CBO
            (Classificação Brasileira de Ocupações) e os dados pessoais foram protegidos, nos termos da Lei Geral de Proteção de
            Dados Pessoais (Lei Federal nº 13.709/2018).
          </p>
        </section>
        <section className="Transparencia__files-container">
          <h3 className="Transparencia__subtitle">Documentos para download</h3>
          <h4 className="Transparencia__ano"><a target="_blank" title="relatorio_2024.zip" href="./docs/relatorio_2024.zip">2024</a></h4>
          <div className='Transparencia__files'>
            <h5 className="Transparencia__semestre"><a target="_blank" title="1_semestre_2024.zip" href="./docs/1_semestre_2024.zip">1º Semestre</a></h5>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2024/1S24 BARRA CARNES.pdf">1S24 BARRA CARNES.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2024/1S24 COMCARNE FRIGORÍFICO STI.pdf">1S24 COMCARNE FRIGORÍFICO STI.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2024/1S24 COMCARNE MATRIZ CD.pdf">1S24 COMCARNE MATRIZ CD.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2024/1S24 RIO GRANDE COHAMA.pdf">1S24 RIO GRANDE COHAMA.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2024/1S24 RIO GRANDE EMPÓRIO CALHAU.pdf">1S24 RIO GRANDE EMPÓRIO CALHAU.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2024/1S24 RIO GRANDE EMPÓRIO PENINSULA.pdf">1S24 RIO GRANDE EMPÓRIO PENINSULA.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2024/1S24 RIO GRANDE EMPÓRIO PONTA D'AREIA.pdf">1S24 RIO GRANDE EMPÓRIO PONTA D'AREIA.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2024/1S24 RIO GRANDE FRIGORÍFICO ITZ.pdf">1S24 RIO GRANDE FRIGORÍFICO ITZ.PDF</a>
            <h5 className="Transparencia__semestre"><a target="_blank" title="2_semestre_2024.zip" href="./docs/2_semestre_2024.zip">2º Semestre</a></h5>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/2_semestre_2024/2S24 BARRA CARNES.pdf">2S24 BARRA CARNES.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/2_semestre_2024/2S24 COMCARNE FRIGORÍFICO STI.pdf">2S24 COMCARNE FRIGORÍFICO STI.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/2_semestre_2024/2S24 COMCARNE_MATRIZ CD.pdf">2S24 COMCARNE_MATRIZ CD.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/2_semestre_2024/2S24 RIO GRANDE COHAMA.pdf">2S24 RIO GRANDE COHAMA.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/2_semestre_2024/2S24 RIO GRANDE EMPÓRIO CALHAU.pdf">2S24 RIO GRANDE EMPÓRIO CALHAU.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/2_semestre_2024/2S24 RIO GRANDE EMPÓRIO IMPERATRIZ.pdf">2S24 RIO GRANDE EMPÓRIO IMPERATRIZ.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/2_semestre_2024/2S24 RIO GRANDE EMPÓRIO PENINSULA.pdf">2S24 RIO GRANDE EMPÓRIO PENINSULA.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/2_semestre_2024/2S24 RIO GRANDE EMPÓRIO PONTA D'AREIA.pdf">2S24 RIO GRANDE EMPÓRIO PONTA D'AREIA.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/2_semestre_2024/2S24 RIO GRANDE FRIGORÍFICO ITZ.pdf">2S24 RIO GRANDE FRIGORÍFICO ITZ.PDF</a>
          </div>

          <i className="Transparencia__separador" />
          <h4 className="Transparencia__ano"><a target="_blank" title="relatorio_2025.zip" href="./docs/relatorio_2025.zip">2025</a></h4>
          <div className='Transparencia__files'>
            <h5 className="Transparencia__semestre"><a target="_blank" title="1_semestre_2025.zip" href="./docs/1_semestre_2025.zip">1º Semestre</a></h5>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2025/1S25 BARRA CARNES.pdf">1S25 BARRA CARNES.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2025/1S25 COMCARNE FRIGORÍFICO STI.pdf">1S25 COMCARNE FRIGORÍFICO STI.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2025/1S25 COMCARNE MATRIZ CD.pdf">1S25 COMCARNE MATRIZ CD.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2025/1S25 RIO GRANDE EMPÓRIO COHAMA.pdf">1S25 RIO GRANDE EMPÓRIO COHAMA.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2025/1S25 RIO GRANDE EMPORIO PENINSULA.pdf">1S25 RIO GRANDE EMPORIO PENINSULA.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2025/1S25 RIO GRANDE EMPORIO PONTA D AREIA.pdf">1S25 RIO GRANDE EMPORIO PONTA D AREIA.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2025/1S25 RIO GRANDE FRIGORÍFICO ITZ.pdf">1S25 RIO GRANDE FRIGORÍFICO ITZ.PDF</a>
            <a className="Transparencia__arquivo" target="_blank" href="./docs/1_semestre_2025/1S25 RIO GRANDE MATRIZ EMPÓRIO CALHAU.pdf">1S25 RIO GRANDE MATRIZ EMPÓRIO CALHAU.PDF</a>
          </div>
        </section>
      </div>
    </section>

  </main>
);

export default Transparencia;
