import React from 'react';
import { Link } from 'react-router-dom';

/* eslint-disable global-require */

export default {
  pecuaria: {
    meta: {
      prev: null,
      next: '/industria',
      title: 'Pecuária',
      position: '01',
      360: null,
      fotos: [
        { id: 1, src: require('../assets/pecuaria/foto1.jpeg'), description: " " },
        { id: 2, src: require('../assets/pecuaria/foto2.jpeg'), description: " " },
        { id: 3, src: require('../assets/pecuaria/foto3.jpeg'), description: " " },
        { id: 4, src: require('../assets/pecuaria/foto4.jpeg'), description: " " },
        { id: 5, src: require('../assets/pecuaria/foto5.jpeg'), description: " " },
        { id: 6, src: require('../assets/pecuaria/foto6.jpeg'), description: " " },
        { id: 7, src: require('../assets/pecuaria/foto7.jpeg'), description: " " },
        { id: 8, src: require('../assets/pecuaria/foto8.jpeg'), description: " " },
        { id: 9, src: require('../assets/pecuaria/foto9.jpeg'), description: " " },
        { id: 10, src: require('../assets/pecuaria/foto10.jpeg'), description: " " },
      ]
    },
    home: {
      link: "/pecuaria",
      title: "Pecuária",
      about: "Garantia de Origem & Responsabilidade no Manejo",
      background: {
        0: require('../assets/pecuaria/pecuaria-banner.jpg'),
        566: require('../assets/pecuaria/pecuaria-newmobile.jpg'),
      }
    },
    banner: {
      background: require('../assets/pecuaria/pecuaria-subheader.jpg'),
      title: 'Garantia de origem e manejo responsável',
      description: 'Garantia, Confiança e Sustentabilidade são tão fundamentais para a FRIBAL, quanto produtividade, eficiência e lucratividade, por isso, focamos na melhoria contínua das nossas práticas e processos, buscando ir além dos atuais requisitos do mercado quando se trata da origem de manejo do nosso rebanho.',
    },
    sections: [
      {
        id: 1,
        image1: require('../assets/pecuaria/pecuaria-1.jpg'),
        image2: require('../assets/pecuaria/pecuaria-2.jpg'),
        title: 'Garantia de origem',
        descriptions: [
        'Nosso rebanho vem dos melhores criadores de gado Nelore do Maranhão, com seleção criteriosa feita por especialistas para garantir o padrão de qualidade da Fribal.',
        ],
      },
      {
        id: 2,
        image1: require('../assets/pecuaria/pecuaria-3.jpg'),
        image2: require('../assets/pecuaria/pecuaria-4.jpg'),
        title: 'Bem estar animal',
        descriptions: [
        'Do bezerro ao boi gordo, o bem-estar animal é nossa prioridade. Com especialistas qualificados e parcerias globais, a Fribal conquistou o 2º lugar no melhor lote de carcaças bovinas da América Latina no Circuito Nelore de Qualidade e a certificação de Saúde e Bem-Estar Animal pela WQS. Pelo segundo ano consecutivo, também alcançamos o 1º lugar no Prêmio de Saúde Animal da Foco Consultoria, reafirmando nosso compromisso com a excelência.',
        ],
      },
      {
        id: 3,
        image1: require('../assets/pecuaria/pecuaria-5.jpg'),
        image2: require('../assets/pecuaria/pecuaria-6.jpg'),
        title: 'Gestão responsável',
        descriptions: [
          'Em todas as etapas — recria, confinamento e agricultura — o controle rigoroso e as modernas técnicas de gestão garantem a sustentabilidade, previsibilidade e qualidade do produto final.',
        ],
      },
    ],
    gallery: [
      {
        id: '1',
        image: require('../assets/pecuaria/pecuaria-1.jpg'),
      },
      {
        id: '2',
        image: require('../assets/pecuaria/pecuaria-2.jpg'),
      },
      {
        id: '3',
        image: require('../assets/pecuaria/pecuaria-3.jpg'),
      },
      {
        id: '4',
        image: require('../assets/pecuaria/pecuaria-4.jpg'),
      },
      {
        id: '5',
        image: require('../assets/pecuaria/pecuaria-5.jpg'),
      },
      {
        id: '6',
        image: require('../assets/pecuaria/pecuaria-6.jpg'),
      },
    ],
    callToAction: {
      title: 'Faça um Tour Virtual 360°',
      description: 'Tudo sobre a FRIBAL em um passeio incrível por cada ambiente. Navegue e conheça melhor nossa estrutura.',
      action: 'https://goo.gl/maps/VoMWS1nFnFP2',
      actionLabel: 'Iniciar Tour',
      background: require('../assets/placeholder-tour.jpg'),
    },
  },
  industria: {
    meta: {
      prev: '/pecuaria',
      next: '/distribuicao-e-exportacao',
      title: 'Indústria',
      position: '02',
      360: '/360/industria/index.html',
    },
    home: {
      link: "/industria",
      title: "Indústria",
      about: "Cuidados, higiene e proteção para garantir a qualidade do produto",
      background: {
        0: require('../assets/industria/industria-banner.jpg'),
        566: require('../assets/industria/industria-newmobile.jpg'),
      }
    },
    banner: {
      background: require('../assets/industria/industria-subheader.jpg'),
      title: 'Tecnologia de ponta ao longo de todo o processo e Procedimentos rigorosamente controlados',
      description: 'Contamos com duas modernas plantas frigoríficas no Maranhão que processam mais 87 milhões de quilos de carne por ano. Com tecnologia de ponta, produzimos seguindo padrões internacionais de qualidade para garantir a segurança alimentar e preservar os nutrientes próprios da carne bovina.',
    },
    sections: [
      {
        id: 4,
        image1: require('../assets/industria/industria-1.jpg'),
        image2: require('../assets/industria/industria-2.jpg'),
        title: 'Plantas eficientes',
        descriptions: [
          'Investimos na modernização de nossas plantas para otimizar recursos, elevar a qualidade dos produtos e garantir mais segurança alimentar aos consumidores.',
        ],
      },
      {
        id: 5,
        image1: require('../assets/industria/industria-3.jpg'),
        image2: require('../assets/industria/industria-4.jpg'),
        title: 'Capacidade produtiva',
        descriptions: [
          'Duas plantas frigoríficas com abate anual de 320 mil cabeças por ano, representado 65% da capacidade de abate do estado.',
          {
            id: 1,
            img: require('../assets/selos.png')
          },
        ],
      },
      {
        id: 6,
        image1: require('../assets/industria/industria-5.jpg'),
        image2: require('../assets/industria/industria-6.jpg'),
        title: 'Qualidade assegurada',
        image: require('../assets/selos.png'),
        descriptions: [
          'Com monitoramento socioambiental na compra de gado, capacitação contínua de nossa equipe e rigor no controle de qualidade, garantimos segurança alimentar e responsabilidade social. Esse compromisso fortalece a Fribal no Brasil e no mundo.',
        ],
      },
    ],
    gallery: [
      {
        id: '1',
        image: require('../assets/industria/industria-1.jpg'),
      },
      {
        id: '2',
        image: require('../assets/industria/industria-2.jpg'),
      },
      {
        id: '3',
        image: require('../assets/industria/industria-3.jpg'),
      },
      {
        id: '4',
        image: require('../assets/industria/industria-4.jpg'),
      },
      {
        id: '5',
        image: require('../assets/industria/industria-5.jpg'),
      },
      {
        id: '6',
        image: require('../assets/industria/industria-6.jpg'),
      },
    ],
    callToAction: {
      title: 'Faça um Tour Virtual 360°',
      description: 'Tudo sobre a FRIBAL em um passeio incrível por cada ambiente. Navegue e conheça melhor nossa estrutura.',
      action: 'https://goo.gl/maps/VoMWS1nFnFP2',
      actionLabel: 'Iniciar Tour',
      background: require('../assets/industria/industria-tour.jpg'),
    },
  },
  distribuicao: {
    meta: {
      prev: '/industria',
      next: '/canais',
      title: 'Distribuição',
      position: '03',
      360: '/360/distribuicao/index.html',
    },
    home: {
      link: "/distribuicao",
      title: "Distribuição",
      about: "Logística integrada de ponta a ponta",
      background: {
        0: require('../assets/distribuicao/distribuicao-banner.jpg'),
        566: require('../assets/distribuicao/distribuicao-newmobile.jpg'),
      }
    },
    banner: {
      background: require('../assets/distribuicao/distribuicao-subheader.jpg'),
      title: 'Produzimos e levamos com toda a segurança, assim chegamos mais longe',
      description: 'De ponta a ponta, todo produto é pesado e identificado e transportado pela própria FRIBAL. Nossa estrutura logística garante que o produto da indústria chegue às lojas em um curto espaço de tempo.',
    },
    gallery: [
      {
        id: '1',
        image: require('../assets/distribuicao/distribuicao-1.jpg'),
      },
      {
        id: '2',
        image: require('../assets/distribuicao/distribuicao-2.jpg'),
      },
      {
        id: '3',
        image: require('../assets/distribuicao/distribuicao-3.jpg'),
      },
      {
        id: '4',
        image: require('../assets/distribuicao/distribuicao-4.jpg'),
      },
      {
        id: '5',
        image: require('../assets/distribuicao/distribuicao-5.jpg'),
      },
      {
        id: '6',
        image: require('../assets/distribuicao/distribuicao-6.jpg'),
      },
    ],
    sections: [
      {
        id: 7,
        image1: require('../assets/distribuicao/distribuicao-1.jpg'),
        image2: require('../assets/distribuicao/distribuicao-2.jpg'),
        title: 'Frota própria',
        descriptions: [
        'Moderna frota de veículos próprios garante a qualidade do produto e a eficiência logística de ponta a ponta, importantes diferenciais da FRIBAL junto aos seus clientes e parceiros.',
        ],
      },
      {
        id: 8,
        image1: require('../assets/distribuicao/distribuicao-3.jpg'),
        image2: require('../assets/distribuicao/distribuicao-4.jpg'),
        title: 'Rastreabilidade e Controle',
        descriptions: [
        '100% dos veículos que transportam os produtos FRIBAL são rastreados geograficamente e a temperatura de suas carretas frigoríficas controladas online assegurando o compromisso com a eficiência da logística e a garantia da qualidade.',
        ],
      },
      {
        id: 9,
        image1: require('../assets/distribuicao/distribuicao-5.jpg'),
        image2: require('../assets/distribuicao/distribuicao-6.jpg'),
        title: 'Exportação a FRIBAL',
        descriptions: [
        'A FRIBAL é a maior exportadora de carne bovina da região nordeste, sozinha é responsável por 80% da carne exportada pelo estado do Maranhão. Alcançando hoje países com elevados padrões de qualidade e trabalhando continuamente em busca da expansão destes horizontes, sempre com a chancela do Ministério de Agricultura, Pecuária e Abastecimento.',
        ],
      },
    ],
    callToAction: {
      title: 'Faça um Tour Virtual 360°',
      description: 'Tudo sobre a FRIBAL em um passeio incrível por cada ambiente. Navegue e conheça melhor nossa estrutura.',
      action: 'https://goo.gl/maps/VoMWS1nFnFP2',
      actionLabel: 'Iniciar Tour',
      background: require('../assets/distribuicao/distribuicao-tour.jpg'),
    },
  },
  canais: {
    meta: {
      prev: '/distribuicao',
      next: '/marcas',
      title: 'Canais de Venda',
      position: '04',
      360: [{ label: 'Entre em contato', link: '/fale-conosco?dp=Exportação', target: '_self' }],
    },
    home: {
      link: "/canais",
      title: "Canais de venda",
      about: "Comercializamos nossos produtos para todo Mundo",
      background: {
        0: require('../assets/canais/canais-banner.jpg'),
        566: require('../assets/canais/canais-newmobile.jpg'),
      }
    },
    banner: {
      background: require('../assets/canais/canais-subheader.jpg'),
      title: 'A FRIBAL comercializa para todo mundo',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint inventore tenetur harum blanditiis pariatur molestiae a amet, laboriosam culpa facilis.',
    },
    gallery: [
      {
        id: '1',
        image: require('../assets/canais/canais-1.jpg'),
      },
      {
        id: '2',
        image: require('../assets/canais/canais-2.jpg'),
      },
      {
        id: '3',
        image: require('../assets/canais/canais-3.jpg'),
      },
      {
        id: '4',
        image: require('../assets/canais/canais-4.jpg'),
      },
      {
        id: '5',
        image: require('../assets/canais/canais-5.jpg'),
      },
      {
        id: '6',
        image: require('../assets/canais/canais-6.jpg'),
      },
      {
        id: '7',
        image: require('../assets/canais/canais-7.jpg'),
      },
      {
        id: '8',
        image: require('../assets/canais/canais-8.jpg'),
      },
    ],
    sections: [
      {
        id: 10,
        image1: require('../assets/canais/canais-1.jpg'),
        image2: require('../assets/canais/canais-2.jpg'),
        title: 'Vendas Internacionais',
        descriptions: [
          'A Fribal, com competência comercial e logística própria, atende aos rigorosos requisitos internacionais com certificação SIF, garantindo qualidade em todo o Brasil.',
        ],
      },
      {
        id: 11,
        image1: require('../assets/canais/canais-3.jpg'),
        image2: require('../assets/canais/canais-4.jpg'),
        title: 'Vendas Nacionais',
        descriptions: [
         'Estamos presentes em todo o Brasil, com competência comercial, produtos de qualidade e logística própria, garantindo a entrega de nossos produtos em todas as regiões.',
        ],
      },
      {
        id: 12,
        image1: require('../assets/canais/canais-5.jpg'),
        image2: require('../assets/canais/canais-6.jpg'),
        title: 'Vendas Regionais',
        descriptions: [
        'Para atender exclusivamente Maranhão e Ceará, contamos com centros de distribuição em São Luís/MA e Fortaleza/CE, além de frota própria, garantindo qualidade e eficiência no serviço.',
        ],
      },
      {
        id: 13,
        image1: require('../assets/canais/canais-7.jpg'),
        image2: require('../assets/canais/canais-8.jpg'),
        title: { href: 'http://www.fribaldelivery.com.br', text: [
            'Food Service',
            <a href="http://www.fribaldelivery.com.br" target="_blank" rel="noopener noreferrer" class="Button Button--primary-outline Section__action">Delivery Maranhão</a>,
          ]
        },
        descriptions: [
        'Nos estados do Maranhão, Piauí e Ceará, criamos uma área Food Service voltada para restaurantes, lanchonetes e cozinhas industriais, oferecendo produtos e logística customizada.',
        ],
      },
    ],
    callToAction: null,
  },
  marcas: {
    meta: {
      prev: '/canais',
      next: '/varejo',
      title: 'Marcas',
      position: '05',
      360: null,
    },
    home: {
      link: "/marcas",
      title: "Marcas",
      about: "Cortes especialmente selecionados",
      background: {
        0: require('../assets/produtos/produtos-banner.jpg'),
        566: require('../assets/produtos/produtos-newmobile.jpg'),
      }
    },
    banner: {
      background: require('../assets/produtos/produtos-subheader.jpg'),
      title: 'Produtos para cada momento de sua vida!',
      selos:[
        { id:1, imagem:require('../assets/selos/selo-diaadia.png')},
        { id:2, imagem:require('../assets/selos/selo-grill.png')},
        { id:3, imagem:require('../assets/selos/selo-selection.png')},
        { id:4, imagem:require('../assets/selos/selo-dryaged.png')},
      ],
      description: 'Além das tradicionais carnes, aqui você encontra mais de 6 mil itens que vão do tempero para aquela receita especial até o vinho que servirá de acompanhamento.',
    },
    gallery: [
      {
        id: '1',
        image: require('../assets/produtos/diaadia-1.jpg'),
      },
      {
        id: '2',
        image: require('../assets/produtos/diaadia-2.jpg'),
      },
      {
        id: '3',
        image: require('../assets/produtos/grill-1.jpg'),
      },
      {
        id: '4',
        image: require('../assets/produtos/grill-2.jpg'),
      },
      {
        id: '5',
        image: require('../assets/produtos/selection-1.jpg'),
      },
      {
        id: '6',
        image: require('../assets/produtos/selection-2.jpg'),
      },
    ],
    sections: [
      {
        id: 14,
        image1: require('../assets/produtos/diaadia-1.jpg'),
        image2: require('../assets/produtos/diaadia-2.jpg'),
        title: 'FRIBAL Dia a Dia',
        descriptions: [
        'Abrangente linha de produtos com cortes do dianteiro, traseiro e miúdos. Apresentados em embalagens a vácuo, congelados e resfriados para atender a todas as necessidades do dia a dia da cozinha doméstica e profissional.',
        ],
      },
      {
        id: 15,
        image1: require('../assets/produtos/grill-1.jpg'),
        image2: require('../assets/produtos/grill-2.jpg'),
        title: 'FRIBAL Grill',
        descriptions: [
        'Produtos criteriosamente selecionados para atender os amantes de um bom churrasco e da culinária gourmet. Os cuidados especiais na manipulação do produto garantem uma qualidade superior.',
        ],
      },
      {
        id: 16,
        image1: require('../assets/produtos/selection-1.jpg'),
        image2: require('../assets/produtos/selection-2.jpg'),
        title: 'FRIBAL Selection',
        descriptions: [
        'Dos melhores e mais jovens rebanhos da pecuária maranhense nasce a linha Selection. Animais precoces, abaixo de 30 meses, manejados e engordados em nossas propriedades com as mais modernas técnicas nutricionais e zootécnicas. Produtos cuidadosamente selecionados e manipulados em nossos frigoríficos garantem os diferenciais desta linha.',
        ],
      },
      {
        id: 21,
        image1: require('../assets/produtos/dry-aged-1.jpg'),
        image2: require('../assets/produtos/dry-aged-2.jpg'),
        title: 'FRIBAL DRY AGED',
        descriptions: [
        'Produto cuidadosamente selecionado com alta suculência, equilíbrio e maciez que surpreendem desde o primeiro corte. O processo é capaz de produzir o 5° sabor, também chamado de Umami, que significa ‘‘delicioso’’. Seu efeito é fundamental para equilibrar o gosto e harmonizar o sabor total do corte.',
        ],
      },
    ],
    callToAction: null,
  },
  varejo: {
    meta: {
      prev: '/distribuicao',
      next: null,
      title: 'Varejo',
      position: '06',
      360: [
        { label: 'Empório', link: '/360/emporio/index.html' },
        { label: 'FRIBAL', link: '/360/sao-francisco/index.html' },
      ],
    },
    home: {
      link: "/varejo",
      title: "Varejo",
      about: "Sempre perto sempre à mesa",
      background: {
        0: require('../assets/varejo/varejo-banner.jpg'),
        566: require('../assets/varejo/varejo-newmobile.jpg'),
      }
    },
    banner: {
      background: require('../assets/varejo/varejo-subheader.jpg'),
      title: 'Todo dia perto de você',
      description: 'Maior rede de casas de carne do Nordeste, as lojas FRIBAL são pioneiras no conceito de boutique de carne, com mais de 50 lojas no estado oferecendo atendimento diferenciado, produtos de qualidade obedecendo à todas as normas de higiene e segurança alimentar.',
    },
    gallery: [
      {
        id: '1',
        image: require('../assets/varejo/varejo-1.jpg'),
      },
      {
        id: '2',
        image: require('../assets/varejo/varejo-2.jpg'),
      },
      {
        id: '3',
        image: require('../assets/varejo/varejo-3.jpg'),
      },
      {
        id: '4',
        image: require('../assets/varejo/varejo-4.jpg'),
      },
      {
        id: '5',
        image: require('../assets/varejo/varejo-5.jpg'),
      },
      {
        id: '6',
        image: require('../assets/varejo/varejo-6.jpg'),
      },
    ],
    sections: [
      {
        id: 17,
        image1: require('../assets/varejo/varejo-1.jpg'),
        image2: require('../assets/varejo/varejo-2.jpg'),
        title: { href: '/lojas?estado=MA', text: [
            'LOJAS DE CARNE FRIBAL',
            <Link key="lojas" to="/lojas?estado=MA" className="Button Button--primary-outline Section__action">+ Lojas</Link>,
          ]
        },
        descriptions: [
        'Pioneirismo no Nordeste com mais de 58 lojas de carne, oferecendo excelência no atendimento e conformidade com normas de segurança alimentar.',
        ],
      },
      {
        id: 18,
        image1: require('../assets/varejo/varejo-5.jpg'),
        image2: require('../assets/varejo/varejo-6.jpg'),
        title: { href: 'http://emporiofribal.com.br/', text: [
            'Empório FRIBAL',
            <a href="http://emporiofribal.com.br/" target="_blank" rel="noopener noreferrer" class="Button Button--primary-outline Section__action">Site do Empório</a>,
          ]
        },
        descriptions: [
          'Conceito Empório Gourmet, no Maranhão e Piauí, com 7 lojas proporcionando experiências gastronômicas premium e exclusivas.',
        ]
      },
      {
        id: 19,
        image1: require('../assets/varejo/varejo-3.jpg'),
        image2: require('../assets/varejo/varejo-4.jpg'),
        title: { href: '/lojas?estado=CE', text: [
            'Shop in Shop',
            <Link key="lojas" to="/lojas?estado=CE" className="Button Button--primary-outline Section__action">+ Lojas</Link>,
          ]
        },
        descriptions: [
          'Presença no Ceará com 120 lojas em parceria com redes de supermercados, consolidando a liderança no setor de carnes com o padrão Fribal.',
        ],
      },
      {
        id: 20,
        image1: require('../assets/varejo/mercearia-1.jpg'),
        image2: require('../assets/varejo/mercearia-2.jpg'),
        title: { href: '/lojas', text: [
            'Mercearia FRIBAL',
            <Link key="lojas" to="/lojas" className="Button Button--primary-outline Section__action">+ Lojas</Link>,
          ]
        },
        descriptions: [
          'Mercearia Fribal com 2 lojas inovando no varejo de proximidade com foco em praticidade e atendimento personalizado.',
        ],
      },
    ],
    callToAction: {
      title: 'Faça um Tour Virtual 360°',
      description: 'Tudo sobre a FRIBAL em um passeio incrível por cada ambiente. Navegue e conheça melhor nossa estrutura.',
      action: 'https://goo.gl/maps/VoMWS1nFnFP2',
      actionLabel: 'Iniciar Tour',
      background: require('../assets/varejo/varejo-tour.jpg'),
    },
  },
};
